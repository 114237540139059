
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Location } from 'vue-router';
import GButton from '@/components/gsk-components/GskButton.vue';

@Component({
  components: {
    GButton,
  },
})
export default class TicketCard extends Vue {
  // slot name string prop
  @Prop({ type: Array, required: true }) listItems!: string[];
  @Prop({ type: String, required: true }) heading!: string;
  @Prop({ type: String, required: true }) description!: string;
  @Prop({ type: String, required: true }) effect!: string;
  @Prop({ type: Object, required: true }) link!: Location;
}
