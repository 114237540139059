var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('FullScreenForm',{staticClass:"fs",attrs:{"title":"Open Ticket","close-route":_vm.closeRoute,"no-center":"","no-flex-center":""}},[_c('Promised',{attrs:{"promise":_vm.promise},scopedSlots:_vm._u([{key:"rejected",fn:function(e){return [_c('Grid',[_c('GridCell',{attrs:{"span":"16"}},[_c('h6',{staticClass:"f-danger"},[_vm._v(" "+_vm._s(e)+" ")])])],1)]}},{key:"pending",fn:function(){return [_c('Grid',[_c('GridCell',{attrs:{"span":"16"}},[_c('gsk-circular-progress',{attrs:{"label":"Loading bot data..."}})],1)],1)]},proxy:true}])},[[_c('Grid',{staticClass:"grid"},[_c('GridCell',{attrs:{"span":"16"}},[_c('h6',{staticClass:"text-center header"},[_vm._v("First, select the type of ticket you’d like to open")])]),_c('GridCell',{attrs:{"span":"2"}}),_c('GridCell',{attrs:{"span":"4"}},[_c('TicketCard',{attrs:{"heading":"Blocking Ticket","description":"Indicates that the bot is broken and failing. This prevents sign offs for the day until the ticket is closed.","effect":"Prevents sign offs","link":_vm.link('blocking'),"list-items":[
              'Bot is broken',
              'Bot is failing',
              'Bot is not completing successfully',
              'Only this bot is impacted by the ticket',
              'The ticket blocks sign offs',
              'Can attach an existing ticket',
            ],"data-cy":"blocking-ticket"}})],1),_c('GridCell',{attrs:{"span":"4"}},[_c('TicketCard',{attrs:{"heading":"System/Intermittent Ticket","description":"Indicates that the bot can not run typically due to a global problem, or to indicate chronic problems that sometimes cause the bot to fail. ","effect":"Does not prevent sign offs.","link":_vm.link('intermittent'),"list-items":[
              'This bot and others can not run properly',
              'There is a system wide issue',
              'Applies to multiple bots',
              'The bot may sometimes run successfully and sometimes fail.',
              'The business expects the bot to be running.',
              'Can attach an existing ticket',
            ],"data-cy":"intermittent-ticket"}})],1),_c('GridCell',{attrs:{"span":"4"}},[_c('TicketCard',{attrs:{"heading":"Enhancement/ Break Fix Ticket","description":"A convenience method to keep track of open issues, break fixes, or enhancements.","effect":"Does not prevent sign offs.","link":_vm.link('nonblocking'),"list-items":[
              'This bot is still running in production',
              'The ticket may be opened for a long time',
              'The business is still depending on the bot running in its current state',
              'Can attach an existing ticket',
            ],"data-cy":"enhancement-ticket"}})],1),_c('GridCell',{attrs:{"span":"2"}})],1)]],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }